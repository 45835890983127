import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import Toolbar from '@/components/toolbar/toolbar';

export default {
    name: "Policy",
    data() {
        return {
        }
    },
    components: {
        Footer, Header, Breadcrumb, Toolbar
    }
};